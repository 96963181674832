import React, { useState } from "react";
import Input from "../Input/Input";
import Layout from "../Layout/Layout";
import { Body1, Headline1 } from "../Typography/Typography";
import Button from "../Button/Button";
import { navigate } from "gatsby";

const Form = ({ setSubmitted }) => {
  const [form, setForm] = useState({
    email: "",
    name: "",
    club: "",
    phone: "",
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const errors = {
    email: {
      valid: form.email.length > 0,
      message: "Testing",
    },
    name: {
      valid: form.name.length > 0,
      message: "Testing",
    },
    club: {
      valid: form.club.length > 0,
      message: "Testing",
    },
  };

  return (
    <form name="contact" method="POST" data-netlify="true" action="/gratulerer">
      <input type="hidden" name="form-name" value="contact" />
      <Input
        name="name"
        id="name"
        type="text"
        value={form.name}
        onChange={onChange}
        label="Navn"
        error={{ valid: true }}
      />
      <Input
        name="email"
        id="email"
        type="email"
        value={form.email}
        onChange={onChange}
        label="E-post"
        error={{ valid: true }}
      />
      <Input
        name="club"
        id="club"
        type="text"
        value={form.club}
        onChange={onChange}
        label="Klubb"
        error={{ valid: true }}
      />
      <Button
        type="submit"
        primary
        disabled={Object.keys(errors)
          .map((key) => errors[key].valid)
          .some((value) => !value)}
      >
        Bekreft
      </Button>
    </form>
  );
};

export default Form;
