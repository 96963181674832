import React from "react";
import styles from "./Input.module.scss";
import { Caption } from "../Typography/Typography";
import { cn } from "../../lib/helpers";

const Input = (props) => {
  let { label, placeholder, error } = props;
  if (!label && !placeholder) return null;
  label = label || placeholder;
  placeholder = placeholder || label;

  const { valid, message } = error;

  return (
    <div className={cn(styles.input, !valid && styles.error)}>
      <input {...props} placeholder={placeholder} />
      <label htmlFor={props.id}>{label}</label>
      <Caption className={styles.error}>{!valid ? message : ""}</Caption>
    </div>
  );
};

export default Input;
