import React, { useState } from "react";
import Form from "../components/Form/Form";
import { Headline1, Body1 } from "../components/Typography/Typography";
import SubmissionWrapper from "../components/SubmissionWrapper/SubmissionWrapper";

const Register = () => {
  return (
    <SubmissionWrapper>
      <div>
        <Headline1 align="center">Kom i gang!</Headline1>
        <Body1 align="center">
          Om du ønsker å ta i bruk digitale dugnader, fyll inn skjemaet
          nedenfor, så tar vi kontakt.
        </Body1>
        <Form />
      </div>
    </SubmissionWrapper>
  );
};

export default Register;
